import { string2number } from './Text'

export function numVal(val, float) {
  if (typeof val === 'number') {
    return val
  }

  let strVal = string2number(val)
  let useFloat = float === true || strVal.includes('.')
  return useFloat ? parseFloat(strVal) : parseInt(strVal, 10)
}

export function renderWithThousandSeparators(value) {
  return numVal(value).toLocaleString('en').replaceAll(',', ' ')
}
