import React from 'react'
import numeral from 'numeral'

export function nl2p(txt) {
  if (txt) {
    return txt.split('\n').map((item, key) => {
      return <p key={key}>{item}</p>
    })
  }

  return null
}
export function nl2br(txt) {
  if (txt) {
    return txt.split('\n').map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      )
    })
  }
  return null
}
export function str_replace(txt1, txt2, str) {
  var txt = str + ''
  if (txt) {
    return txt.replace(txt1, txt2)
  }
  return str
}

export function numVal(val, isFloat = val.includes('.')) {
  if (typeof val === 'number' && !isNaN(val)) {
    return val
  }

  if (!val) {
    return 0
  }

  let numericVal = (val || '0').replaceAll(',', '.').replaceAll(/[^\d.]/g, '')

  if (isFloat) {
    numericVal = parseFloat(numericVal)
  } else {
    numericVal = parseInt(numericVal, 10)
  }

  if (!numericVal || isNaN(numericVal)) {
    return 0
  }

  return numericVal
}

export function string2number(str) {
  if (typeof str === 'number') {
    return str
  }

  let txt = str_replace(',', '.', str + '')

  if (txt) {
    const input = txt.replace(/[^\d.]/g, '')

    if (txt === '0') {
      return txt
    }

    return input
  }

  return 0
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace)
}

export function formatThousandsWithRounding(n, dp) {
  if (!n && n !== 0) {
    return null
  }

  if (typeof n === 'string') {
    n = parseFloat(n)
  }

  n = Math.round(n * 10000) / 10000

  var thousandSeparator = '\u00A0'
  var decimalSeparator = ','
  var number = ''
  var decimalStr = ''
  if (dp) {
    decimalStr = '.'
    for (var i = 0; i < dp; i++) {
      decimalStr += '0'
    }
  }

  number = numeral(n).format('0,0' + decimalStr)

  const thousandTemp = replaceAll(number, ',', thousandSeparator)

  return thousandTemp.replace(',', thousandSeparator).replace('.', decimalSeparator)
}
export function moneyFormat(n, c, d, t) {
  return formatThousandsWithRounding(n, c)
  /*
  var c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d === undefined ? "," : d,
    t = t === undefined ? '\u00A0' : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;
   return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
   */
}
export function getById(id, array) {
  for (var i in array) {
    var row = array[i]
    if (row) {
      if (row.id === id) {
        return row
      }
    }
  }
  return null
}
