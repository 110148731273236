const variables = {
  bpMobile: '650px',
  bpSmall: '920px',
  bpMedium: '1100px',
  bpLarge: '1200px',

  rootFontSize: '10px',
  bodyFontSize: '1.6rem',
  smallFontSize: '1.2rem',
  tinyFontSize: '0.9rem',
  leadFontSize: '2rem',
  h1FontSize: '3.8rem',
  h1FontSizeSmaller: '3.6rem',
  h1FontSizeMobile: '3rem',
  h1FontSizeMobileSmaller: '2.6rem',
  h2FontSizeMobile: '2rem',
  h2FontSize: '2.6rem',
  h3FontSize: '2rem',
  h4FontSize: '1.6rem',
  navMobileFontSize: '2rem',
  navFontSize: '1.4rem',

  rootLineHeight: 1.4,
  leadLineHeight: 1.222,
  titleLineHeight: 1.15,

  rootLetterSpacing: '0.025em',

  fontFamily: 'proxima-nova',

  maxWidthPage: '1280px',
  maxWidthCol: '60rem',

  maxWidthFormNarrow: '35rem',
  maxWidthFormDefault: '55rem',

  navigationHeight: '7rem',
  backHeaderHeight: '5rem',
  footerHeight: '5rem',

  gutter: '1.5rem',
  gutterHalf: '0.75rem',
  paddingVerticalSection: '6rem',
  paddingVerticalSectionBottom: '2rem',

  borderRadius: '0.2rem',

  slowSpeed: '0.6s',
  defaultSpeed: '0.3s',
  defaultEasing: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
}

export default variables
