import React, { useState, useEffect, useMemo, useCallback } from 'react'
import translate from 'counterpart'
import styled from 'styled-components/macro'
import * as UserActions from '../../actions/UserActions'
import EmptyProperty from './items/EmptyProperty'
import PropertyBlock from './items/PropertyBlock'
import Dropdown from '../shared/Dropdown'
import Alert from '../shared/Alert'
import Link from '../shared/Link'
import StatBox from '../shared/StatBox'
import Spacer from '../shared/Spacer'
import UserListItem from '../shared/UserListItem'
import Color from '../../styles/color'
import Variables from '../../styles/variables'
import iconAvatar from '../../images/icon_avatar.svg'
import iconBuyers from '../../images/info-potential-buyers.svg'
import LeadGenerator from './LeadGenerator'

const PropertyActionRow = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 9;
  flex-wrap: wrap;
  align-items: center;

  > div:first-child {
    margin-right: 0;
    margin-bottom: ${Variables.gutterHalf};
    max-width: 100%;

    @media (min-width: ${Variables.bpSmall}) {
      margin-right: ${Variables.gutter};
    }
  }
  .select-text {
    font-weight: bold;
  }
`

const ActionWrapper = styled.div`
  > div {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -${Variables.gutterHalf};

    a {
      margin: ${Variables.gutterHalf};
    }
  }
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${Variables.bpSmall}) {
    flex-direction: row;

    > div:first-child {
      width: 60%;
    }

    > div:last-child {
      width: 40%;
      padding-left: ${Variables.gutter};
    }
  }
`

const StatsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin: -${Variables.gutterHalf};

  > div {
    flex-basis: calc(33.333% - ${Variables.gutter});
    margin: ${Variables.gutterHalf};
    flex-grow: 1;
  }
`

function buildDropdownItems(userProperties) {
  let dropdownItems = []

  for (let property of userProperties) {
    let propertyItem = {}
    let streetName = ''
    let streetNumber = ''
    let postalCode = ''
    let city = ''

    if (property.locationParts) {
      if (property.locationParts.streetName) {
        streetName = property.locationParts.streetName
      }
      if (property.locationParts.streetNumber) {
        streetNumber = property.locationParts.streetNumber
      }
      if (property.locationParts.postalCode) {
        postalCode = property.locationParts.postalCode
      }
      if (property.locationParts.city) {
        city = property.locationParts.city
      }
    }

    propertyItem.name = streetName + ' ' + (streetNumber || '')
    propertyItem.subname = (postalCode ? postalCode + ' ' : '') + (city || '')
    propertyItem.value = property.id
    dropdownItems.push(propertyItem)
  }

  return dropdownItems
}

const SELECTED_PROPERTY_LOCALSTORAGE_KEY = 'muuvin:selectedProperty'

const PropertyFrontpage = ({ property, getWatchResults, getHouseStats, housetype }) => {
  let [selectedPropertyId, setSelectedPropertyId] = useState(undefined)

  let userProperties = useMemo(() => property.myProperties || [], [property.myProperties])
  let selectedProperty = useMemo(
    () => userProperties.find((prop) => prop.id === selectedPropertyId),
    [userProperties, selectedPropertyId]
  )

  let propertyDropdownItems = useMemo(() => buildDropdownItems(userProperties), [userProperties])
  let selectedDropdownItem = useMemo(
    () => propertyDropdownItems.find((item) => item.value === selectedPropertyId),
    [propertyDropdownItems, selectedPropertyId]
  )

  // Auto-select first dropdown item
  useEffect(() => {
    if (userProperties.length === 0) {
      return
    }

    let selectId = localStorage.getItem(SELECTED_PROPERTY_LOCALSTORAGE_KEY)
    // Ensure the ID from localStorage is one of the user's properties.
    if (!selectId || !userProperties.find((prop) => prop.id === selectId)) {
      selectId = userProperties[0].id
    }

    setSelectedPropertyId((current) => current || selectId)
  }, [userProperties])

  useEffect(() => {
    if (selectedPropertyId && typeof selectedPropertyId === 'string') {
      localStorage.setItem(SELECTED_PROPERTY_LOCALSTORAGE_KEY, selectedPropertyId)
    }
  }, [selectedPropertyId])

  useEffect(() => {
    let id = selectedProperty?.id

    if (id) {
      getWatchResults(id)
      getHouseStats(id)
    }
  }, [selectedProperty])

  let onChangeDropdownSelection = useCallback((item) => {
    if (item?.value) {
      setSelectedPropertyId(item.value)
    }
  }, [])

  // TODO: Loader when properties are loading
  if (!selectedProperty) {
    return <EmptyProperty />
  }

  let editContent,
    content,
    matches,
    infoBoxes,
    views,
    listed,
    favourites,
    numberOfLikes,
    numberOfComments

  if (propertyDropdownItems.length !== 0) {
    if (propertyDropdownItems.length > 1) {
      content = (
        <div>
          <div className="select-text">{translate('common.changeProperty')}</div>
          <Dropdown
            list={propertyDropdownItems}
            selected={selectedDropdownItem}
            onChange={onChangeDropdownSelection}
          />
        </div>
      )
    } else {
      content = <h2>{translate('dashboard.property.myPropertyTitle')}</h2>
    }

    if (selectedProperty) {
      editContent = (
        <div>
          {!selectedProperty.private && (
            <Link key="show" type="outline" to={`/property/${selectedProperty.id}`}>
              {translate('common.show')}
            </Link>
          )}
          <Link key="edit" type="outline" to={`/property/edit/${selectedProperty.id}`}>
            {translate('common.edit')}
          </Link>
          <Link key="new" type="button" to={'/property/new'}>
            {translate('common.addProperty')}
          </Link>
        </div>
      )
    }
  } else {
    content = <EmptyProperty />
  }

  let potentialBuyers = []
  let count = 0

  if (userProperties.length !== 0 && (property?.houseWatchers || []).length !== 0) {
    let watches = property.houseWatchers

    for (let watch of watches) {
      let user = watch.user
      let name = user.name || translate('common.anonymous')
      let url = '/buyer/' + watch.id + '/' + selectedProperty.value

      potentialBuyers.push(
        <UserListItem
          key={`potential_buyer_${user.id}`}
          avatarSrc={UserActions.getAvatarUrl(user)}
          linkUrl={url}
          name={name}
        />
      )

      if (typeof user !== 'string') {
      } else {
        potentialBuyers.push(<UserListItem key={`potential_buyer_${count}`} name={user} />)
      }
      count++
    }

    let text = <p>{translate('dashboard.property.potentialBuyersText')}</p>

    matches = (
      <>
        <h3>
          {translate('dashboard.property.potentialBuyers2')}{' '}
          <span style={{ color: Color.green }}>{count}</span>
        </h3>
        <Alert iconSrc={iconAvatar} alignItems="top">
          {text}
        </Alert>
        <div>{potentialBuyers}</div>
      </>
    )
  }

  let imageBlock

  if (selectedProperty) {
    imageBlock = (
      <PropertyBlock
        key="propertyBlock"
        property={selectedProperty}
        dontLink={true}
        imageSize={2048}
        showPrivateTag={true}
        houseType={housetype}
      />
    )
  }

  if (selectedProperty) {
    let stats = property.houseStats

    numberOfLikes = selectedProperty.numberOfLikes || 0
    numberOfComments = selectedProperty.numberOfComments || 0

    if (stats) {
      views = stats.data.views || 0
      listed = stats.data.shows || 0
      favourites = stats.data.favorites || 0
    }

    infoBoxes = (
      <div>
        <h3>{translate('dashboard.property.myPropertyTitle')}</h3>
        <StatsRow>
          <StatBox title={views || '–'} description={translate('house.views')} />
          <StatBox title={listed || '–'} description={translate('house.listed')} />
          <StatBox title={favourites || '–'} description={translate('house.favourites')} />
          <StatBox title={numberOfLikes || '–'} description={translate('house.numberOfLikes')} />
          <StatBox
            title={numberOfComments || '–'}
            description={translate('house.numberOfComments')}
          />
        </StatsRow>
      </div>
    )
  }

  return (
    <div>
      <Alert iconSrc={iconBuyers}>
        <p>
          {translate('dashboard.property.houseIsCurrently')}&nbsp;
          <strong>
            {selectedProperty.private ? translate('common.private') : translate('common.public')}
          </strong>
        </p>
      </Alert>
      <Spacer />
      <PropertyActionRow>
        {content}
        <ActionWrapper>{editContent}</ActionWrapper>
      </PropertyActionRow>
      <Spacer />
      {imageBlock}
      <ColumnWrapper>
        <div>
          {infoBoxes}
          <LeadGenerator propertyId={selectedProperty?.id} />
        </div>
        <div>{matches}</div>
      </ColumnWrapper>
    </div>
  )
}

export default PropertyFrontpage
