import * as ActionTypes from '../constants/ActionTypes'

const initialState = {
  property: null,
  lastProperty: null,
  myProperties: null,
  tempProperty: null,
  oldProperty: null,
  houseWatchers: null,
  tempGeoCode: null,
  updateTime: 0,
  houseStats: null,
  featured: { liked: [], commented: [], houses: [] }
}

function PropertyReducer(state = initialState, action) {
  var date = new Date()
  var now = date.getTime()

  if (action) {
    let featured = state.featured

    switch (action.type) {
      case ActionTypes.FEATURED_GET_LIKED:
        featured.liked = action.data

        return Object.assign({}, state, {
          featured,
          updateTime: now
        })
      case ActionTypes.FEATURED_GET_COMMENTED:
        featured.commented = action.data

        return Object.assign({}, state, {
          featured,
          updateTime: now
        })
      case ActionTypes.FEATURED_GET_BY_AREA:
        featured.houses = action.data

        return Object.assign({}, state, {
          featured,
          updateTime: now
        })
      case ActionTypes.GET_HOUSE_STATS:
        if (Array.isArray(action.data) && action.data.length !== 0) {
          return Object.assign({}, state, {
            houseStats: action.data[0],
            updateTime: now
          })
        } else {
          return Object.assign({}, state, {
            houseStats: null,
            updateTime: now
          })
        }
      case ActionTypes.SAVE_TEMP_PROPERTY:
        return Object.assign({}, state, {
          tempProperty: action.data,
          updateTime: now
        })

      case ActionTypes.POST_PROPERTY:
        return Object.assign({}, state, {
          lastProperty: action.data,
          loadError: action.error || null,
          saved: true,
          updateTime: now
        })
      case ActionTypes.GET_PROPERTY:
        return Object.assign({}, state, {
          lastProperty: action.data,
          loadError: action.error || null,
          saved: false,
          updateTime: now
        })
      case ActionTypes.GET_USER_PROPERTIES:
        return Object.assign({}, state, {
          myProperties: action.data,
          updateTime: now
        })
      case ActionTypes.GET_PROPERTY_WATCHES:
        return Object.assign({}, state, {
          houseWatchers: action.data,
          updateTime: now
        })
      case ActionTypes.CLEAR_TEMP_PROPERTY:
        return Object.assign({}, state, {
          tempProperty: null,
          updateTime: now
        })
      case ActionTypes.LOGOUT_SUCCESS:
        return Object.assign({}, state, {
          property: null,
          lastProperty: null,
          loadError: null,
          myProperties: null,
          houseWatchers: null,
          updateTime: 0
        })
    }
  }

  return state
}

export default PropertyReducer
