import React from 'react'
import { connect } from 'react-redux'

import BasicPageContainer from './BasicPageContainer'
import Logo from '../components/shared/Logo'
import BackHeader from '../components/shared/BackHeader'
import Color from '../styles/color'
import Variables from '../styles/variables'
import Link from '../components/shared/Link'
import translate from 'counterpart'

import styled from 'styled-components/macro'
import { changeLanguage } from '../actions/LanguageActions'
import LoginButton from '../components/shared/LoginButton'
import MessageBanner from '../components/shared/MessageBanner'

const ContentWrapper = styled.div`
  margin-top: ${Variables.navigationHeight};
`

const UserHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: ${Variables.navigationHeight};
  width: 100%;
  border-bottom: 1px solid ${Color.border};
  background-color: ${Color.white};

  > div {
    position: relative;
    max-width: ${Variables.maxWidthPage};
    padding: 0 ${Variables.gutter};
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  left: ${Variables.gutter};
`

const NavBPSmall = '650px'
const Nav = styled.nav`
  max-width: calc(100% - 10rem);
  font-size: ${Variables.smallFontSize};

  @media (min-width: ${NavBPSmall}) {
    font-size: ${Variables.bodyFontSize};
  }

  > ul {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;

    > li {
      padding-left: ${Variables.gutterHalf};
      padding-right: ${Variables.gutterHalf};

      &:nth-of-type(2) {
        display: none;

        @media (min-width: ${NavBPSmall}) {
          display: block;
        }
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }
`

const LoggedOutContainer = ({
  language,
  changeLanguageForce,
  children,
  maxWidth,
  customTitle,
  backUrl,
  backLinkText
}) => {
  const handleChangeLanguage = (to) => (e) => {
    e.preventDefault()
    changeLanguageForce(to)
  }

  const renderLanguageSelector = () => {
    switch (language) {
      case 'en':
        return (
          <Link type="menu" onClick={handleChangeLanguage('fi')} to="#">
            Suomeksi
          </Link>
        )
      case 'fi':
        return (
          <Link type="menu" onClick={handleChangeLanguage('en')} to="#">
            In English
          </Link>
        )
    }
  }

  let backHeader = null

  if (backUrl) {
    backHeader = (
      <BackHeader backUrl={backUrl} customTitle={customTitle} backLinkText={backLinkText} />
    )
  }

  const hasBackUrl = !!backUrl

  return (
    <div className="LoggedOut">
      <UserHeaderContainer>
        <div>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <Nav>
            <ul>
              <li>{renderLanguageSelector()}</li>
              <li>
                <LoginButton />
              </li>
              <li>
                <Link to="/register" type="button" size="small">
                  {translate('form.login.register')}
                </Link>
              </li>
            </ul>
          </Nav>
        </div>
      </UserHeaderContainer>
      <ContentWrapper>
        {backHeader}
        <BasicPageContainer maxWidth={maxWidth} hasBackNav={hasBackUrl}>
          {children}
        </BasicPageContainer>
      </ContentWrapper>
    </div>
  )
}

const mapStateToProps = (state) => {
  var lang = state.auth.language
  return {
    user: state.auth.user,
    language: lang,
    time: state.auth.time
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeLanguageForce: (lang) => {
    dispatch(changeLanguage(lang))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LoggedOutContainer)
