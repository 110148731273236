import * as ActionTypes from '../constants/ActionTypes'
import { createApiRequest } from '../utils/Api'
import { fetchStart, fetchEnd } from './FetchActions'

import * as PageActions from './PageActions'
import { string2number } from '../utils/Text'

export function receiveHouseStats(data) {
  return {
    type: ActionTypes.GET_HOUSE_STATS,
    data: data
  }
}

export function receiveGetWatchResults(data) {
  return {
    type: ActionTypes.GET_WATCH_RESULTS,
    data: data
  }
}
export function receiveGetHouseWatches(data) {
  return {
    type: ActionTypes.GET_PROPERTY_WATCHES,
    data: data
  }
}

export function receiveTempProperty(data) {
  return {
    type: ActionTypes.SAVE_TEMP_PROPERTY,
    data: data
  }
}
export function clearTemporaryProperty() {
  return {
    type: ActionTypes.CLEAR_TEMP_PROPERTY,
    data: null
  }
}

export function receivePostProperty(data = null, error = null) {
  return {
    type: ActionTypes.POST_PROPERTY,
    data,
    error
  }
}
export function receiveGetProperty(data = null, error = null) {
  return {
    type: ActionTypes.GET_PROPERTY,
    data,
    error
  }
}
export function receiveUnloadProperty() {
  return {
    type: ActionTypes.GET_PROPERTY,
    data: null,
    error: null
  }
}

export function receiveDeleteProperty(error = null) {
  return {
    type: ActionTypes.GET_PROPERTY,
    data: null,
    error
  }
}

export function receiveGetOwnProperties(data) {
  return {
    type: ActionTypes.GET_USER_PROPERTIES,
    data: data
  }
}

export function saveTemporaryProperty(saveData) {
  var data = saveData.house

  return (dispatch) => {
    var formData = {}
    formData.name =
      data.locationParts.streetName +
      ' ' +
      data.locationParts.streetNumber +
      ', ' +
      data.locationParts.city
    formData.location = formData.name
    formData.houseType = data.houseType
    formData.description = data.description
    formData.geolocation = data.geolocation
    formData.area = data.area
    formData.price = parseFloat(string2number(data.price))
    formData.private = true
    formData.size = parseFloat(string2number(data.size))
    formData.locationParts = data.locationParts

    dispatch(receiveTempProperty(JSON.stringify(formData)))
  }
}

export function createPropertyFromTempProperty() {
  return (dispatch, getState) => {
    let state = getState()
    let property = state.property?.tempProperty

    if (!property) {
      return
    }

    dispatch(createProperty(property, false))
    dispatch(clearTemporaryProperty())
  }
}

export function createProperty(saveData, redirect = true) {
  var formData = {}
  var METHOD = 'POST'
  var URL = '/house'

  if (saveData.id) {
    METHOD = 'PATCH'
    URL = `/house/${saveData.id}`
  }

  formData.name = saveData.location
  formData.location = saveData.location
  formData.houseType = saveData.houseType
  formData.description = saveData.description
  formData.geolocation = saveData.geolocation
  formData.area = saveData.area
  formData.price = parseFloat(string2number(saveData.price))
  formData.private = saveData.private || false
  formData.size = parseFloat(string2number(saveData.size))
  formData.locationParts = saveData.locationParts
  formData.externalurl = saveData.externalurl
  formData.images = []
  formData.isForSale = saveData.isForSale

  if (saveData.headerImage) {
    formData.images.push(saveData.headerImage)
  }

  if (saveData.image) {
    for (let img of saveData.image) {
      if (img) {
        formData.images.push(img)
      }
    }
  }

  return (dispatch) => {
    let apiRequest = createApiRequest()

    return apiRequest({ path: URL, method: METHOD, json: formData })
      .then((json) => {
        dispatch(receivePostProperty(json.data))

        if (redirect) {
          dispatch(getMyProperties())
          PageActions.changePage('/property')
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch(receiveGetProperty(null, error.message || 'An error occurred.'))
      })
  }
}
export function unloadProperty() {
  return (dispatch, getState) => {
    dispatch(receiveUnloadProperty())
  }
}
export function getPropertyById(id) {
  var URL = `/house/${id}`

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch(receiveGetProperty(json.data))
      })
      .catch((error) => {
        console.log(error)
        dispatch(receiveGetProperty(null, error.message || 'Property was not found.'))
      })
  }
}
export function deleteProperty(id) {
  var URL = `/house/${id}`
  var METHOD = 'DELETE'

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: URL, method: METHOD })
      .then((json) => {
        dispatch(receiveDeleteProperty(json.data))
        dispatch(getMyProperties())
        PageActions.changePage('/property')
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function getMyProperties() {
  var URL = '/house'

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch(receiveGetOwnProperties(json.data))
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
export function getWatchesByHouse(id) {
  var URL = `/house/watches?house=${id}`
  var METHOD = 'POST'

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: URL, method: METHOD })
      .then((json) => {
        dispatch(receiveGetHouseWatches(json.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function getHouseStats(identifier) {
  var URL = `/statistic/?type=house_statistic&identifier=${identifier}`

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    dispatch(receiveHouseStats(null))
    dispatch(fetchStart('housestats'))

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch(receiveHouseStats(json.data))
        dispatch(fetchEnd('housestats'))
      })
      .catch((error) => {
        dispatch(fetchEnd('housestats'))
      })
  }
}

export function getFeaturedByComments(query) {
  let fetchId = 'featured_by_comments'
  let URL = `/house/?searchType=most-comments${query}`

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    dispatch(fetchStart(fetchId))

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch({ type: ActionTypes.FEATURED_GET_COMMENTED, data: json.data })
        dispatch(fetchEnd(fetchId))
      })
      .catch((error) => {
        dispatch(fetchEnd(fetchId))
      })
  }
}

export function getFeaturedByArea(query) {
  let fetchId = 'featured_by_area'
  let URL = `/areastats/?t=${query}`

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    dispatch(fetchStart(fetchId))

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch({ type: ActionTypes.FEATURED_GET_BY_AREA, data: json.data })
        dispatch(fetchEnd(fetchId))
      })
      .catch((error) => {
        dispatch(fetchEnd(fetchId))
      })
  }
}

export function getFeaturedByMostLiked(query) {
  let fetchId = 'featured_by_liked'
  let URL = `/house/?searchType=most-liked${query}`

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    dispatch(fetchStart(fetchId))

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch({ type: ActionTypes.FEATURED_GET_LIKED, data: json.data })
        dispatch(fetchEnd(fetchId))
      })
      .catch((error) => {
        dispatch(fetchEnd(fetchId))
      })
  }
}
