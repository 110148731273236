import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Measure from 'react-measure'
import styled from 'styled-components/macro'

import Color from '../../styles/color'
import Variables from '../../styles/variables'

const boxBp = 150
const Box = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: ${(props) => (props.noBorder ? '0 none' : '0.1rem solid ' + Color.border)};
    padding: ${(props) => (props.layout === 'mini' ? '1rem 0.5rem' : '2.5rem 1rem')};
    border-radius: ${Variables.borderRadius};
    text-align: center;
    position: relative;

    > div {
      > h2 {
        font-size: ${(props) =>
          props.layout === 'mini'
            ? Variables.h4FontSize
            : props.layout === 'huge'
            ? Variables.h2FontSize
            : props.width < boxBp
            ? Variables.h4FontSize
            : Variables.h3FontSize};
        margin: 0;
      }

      > p {
        font-size: ${(props) =>
          props.width < boxBp || props.layout === 'mini'
            ? Variables.smallFontSize
            : Variables.bodyFontSize};
        margin: 0;
      }
    }
  }
`

export default class StatBox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dimensions: {
        width: -1,
        height: -1
      }
    }
  }

  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.string,
    layout: PropTypes.string,
    noBorder: PropTypes.bool
  }

  static defaultProps = {
    layout: 'normal'
  }

  render() {
    const { title, description, layout, style, noBorder } = this.props
    const { width } = this.state.dimensions

    return (
      <Measure
        onMeasure={(dimensions) => {
          this.setState({ dimensions })
        }}>
        <Box style={style} width={width} layout={layout} noBorder={noBorder}>
          <div>
            <div>
              <h2>&nbsp;{title}&nbsp;</h2>
              {description && <p>{description}</p>}
            </div>
          </div>
        </Box>
      </Measure>
    )
  }
}
