import React, { Component } from 'react'
import { Link } from 'react-router'
import Color from '../../styles/color'
import Variables from '../../styles/variables'
import styled from 'styled-components/macro'
import logoSvg from '../../images/logo-app.svg'

const LogoWrapper = styled.div`
  .logo {
    display: flex;
    text-decoration: none;
  }
`

const LogoImg = styled.img`
  display: block;
  width: 10rem;
  height: auto;
`

const LogoText = styled.sup`
  color: ${Color.green};
  margin-left: 0.25em;
  font-size: ${Variables.tinyFontSize};
`

export default class Logo extends Component {
  render() {
    return (
      <LogoWrapper>
        <Link className="logo" to={'/'}>
          <LogoImg src={logoSvg} />
          <LogoText>Beta</LogoText>
        </Link>
      </LogoWrapper>
    )
  }
}
