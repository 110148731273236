import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import translate from 'counterpart'
import jQuery from 'jquery'
import { get } from 'lodash'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import * as AreaActions from '../../actions/AreaActions'
import { roundPrice } from '../../actions/AreaActions'
import { string2number } from '../../utils/Text'

import Button from '../shared/Button'
import ErrorMessage from '../shared/ErrorMessage'
import InputField from '../shared/InputField'
import TextArea from '../shared/TextArea'
import Alert from '../shared/Alert'
import RadioField from '../shared/RadioField'
import ToggleField from '../shared/ToggleField'
import Collapsible from '../shared/Collapsible'
import StatBox from '../shared/StatBox'
import Spacer from '../shared/Spacer'
import { changePage } from '../../actions/PageActions'
import iconAvatar from '../../images/icon_avatar.svg'
import { Notice } from '../shared/Notice'
import orderBy from 'lodash/sortBy'
import { findDOMNode } from 'react-dom'
import { hasPaidLicense } from '../../actions/LicenseActions'
import GetLicenseOverlay from '../shared/GetLicenseOverlay'
import { getObjectHash } from '../../utils/getObjectHash'
import { withRouter } from 'react-router'
import UploadImageField from '../shared/UploadImageField'

let delayTimer
let delayTimerFormUpdate

const Wrapper = styled.div`
  > div:nth-child(2) {
    display: none;
  }

  @media (min-width: ${Variables.bpSmall}) {
    display: flex;
    justify-content: center;

    > div:first-child {
      width: 60%;
    }

    > div:nth-child(2) {
      margin-top: 4rem;
      display: block;
      width: 40%;
      padding-left: 10%;
    }

    .alert--top {
      margin-top: 4rem;
    }
  }
`

const RadioWrapper = styled.div`
  margin: -1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`

const PublicStatusWrapper = styled.div``

const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;

  > div {
    width: calc(33.333% - 2rem);
    margin: 1rem;
  }
`

const ColWrapper = styled.div`
  width: 25rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`

class PropertyAdd extends Component {
  addressInput = null

  constructor(props) {
    super(props)

    this.state = {
      propertyTimeStamp: 0,
      propertyId: null,
      size: null,
      postalCode: null,
      initialized: false
    }

    this._fetchPotentialBuyers = this._fetchPotentialBuyers.bind(this)
    this.checkProperty = this.checkProperty.bind(this)
    this.autoCompleteChanged = this.autoCompleteChanged.bind(this)
    this.doSearch = this.doSearch.bind(this)
    this.getGeocodingAddress = this.getGeocodingAddress.bind(this)
    this.getGeocoding = this.getGeocoding.bind(this)
    this.geocodeResultArray = this.geocodeResultArray.bind(this)
    this.assignAddressFormElements = this.assignAddressFormElements.bind(this)
    this.updateAreaWithPostalCode = this.updateAreaWithPostalCode.bind(this)
    this._handleSizeChange = this._handleSizeChange.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.getAreaPrice = this.getAreaPrice.bind(this)
    this._handleDelete = this._handleDelete.bind(this)
    this._renderPotentialBuyers = this._renderPotentialBuyers.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handlePublicForSaleChange = this.handlePublicForSaleChange.bind(this)

    const google = window?.google

    if (google) {
      this.geocoder = new google.maps.Geocoder()
    }
  }

  componentDidMount() {
    let options = {
      types: ['address']
    }

    const google = window?.google
    let input = findDOMNode(this.addressInput?.getRenderedComponent()?.inputRef)

    if (input && google) {
      this.autocomplete = new google.maps.places.Autocomplete(input, options)
      this.autocomplete.addListener('place_changed', this.autoCompleteChanged)
    }

    this.checkProperty()

    let _this = this
    delayTimerFormUpdate = setTimeout(function () {
      _this._fetchPotentialBuyers()
    }, 2000)

    if (this.props.router && this.props.route) {
      this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave)

      window.onbeforeunload = (e) => {
        let unsavedChangesPrompt = this.routerWillLeave()

        if (unsavedChangesPrompt) {
          e.returnValue = unsavedChangesPrompt
        }
      }
    }
  }

  routerWillLeave = () => {
    if (this.isDirty()) {
      return translate('common.changesNotSaved')
    }
  }

  _fetchPotentialBuyers() {
    let house = this.props.formValues

    let formData = {
      house: {
        houseType: house.houseType,
        geolocation: house.geolocation,
        price: house.price,
        size: parseFloat(string2number(house.size))
      }
    }

    if (house && house.houseType) {
      this.props.getWatcherByParams(formData)
    }
  }

  checkProperty() {
    if (this.state.initialized) {
      return
    }

    let p = this.props.property
    let property = null

    if (p) {
      property = p.lastProperty
    }

    if (property) {
      let propertyForm = {}
      propertyForm.address = property.location
      propertyForm.name = property.name
      propertyForm.id = property.id
      propertyForm.houseType = property.houseType
      propertyForm.size = property.size
      propertyForm.externalurl = property.externalurl || ''
      propertyForm.price = property.price
      propertyForm.houseVisible = property.private ? 'hidden' : 'visible'
      propertyForm.description = property.description

      if (property.images) {
        let headerImage
        let images = orderBy(property?.images || [], 'order', 'ASC')

        if (images[0]) {
          headerImage = images[0].id
          propertyForm.headerImage = headerImage
        }

        propertyForm.image = []

        for (let img of images || []) {
          if (img && img.id !== headerImage) {
            propertyForm.image.push(img.id)
          }
        }
      }

      propertyForm.geolocation = property.geolocation
      propertyForm.locationParts = property.locationParts
      propertyForm.area = property.area
      propertyForm.location = property.location
      propertyForm.isForSale = property.isForSale
      propertyForm.private = property.private

      this.getAreaPrice(property.locationParts?.postalCode, 1)
      this.props.initialize(propertyForm)

      this.setState({
        size: property.size,
        postalCode: property.locationParts.postalCode,
        initialized: true
      })
    }
  }

  checkDirty = true

  disableDirtyCheck = () => {
    this.checkDirty = false
    window.onbeforeunload = undefined
  }

  isDirty = () => {
    let { initialized } = this.state

    if (!this.checkDirty || !initialized) {
      return false
    }

    return this.props.isDirty
  }

  autoCompleteChanged() {
    let place = this.autocomplete?.getPlace()
    this.assignAddressFormElements(place)
  }

  componentDidUpdate(prevProps) {
    this.checkProperty()

    let { updateTime } = this.props

    if (updateTime && prevProps.updateTime !== updateTime) {
      clearTimeout(delayTimerFormUpdate)

      delayTimerFormUpdate = setTimeout(() => {
        this._fetchPotentialBuyers()
      }, 500)
    }
  }

  doSearch() {
    clearTimeout(delayTimer)
    delayTimer = setTimeout(this.getGeocodingAddress, 500) // Will do the ajax stuff after 1000ms, or 1s
  }

  getGeocodingAddress() {
    let address = jQuery('input#address').val()
    this.getGeocoding(address)
  }

  getGeocoding(address) {
    if (this.geocoder) {
      this.geocoder.geocode({ address }, this.geocodeResultArray)
    }
  }

  geocodeResultArray(resultArray, status) {
    if (this.state.error === translate('error.streetNumber')) {
      this.setState({ error: null })
    }

    if (resultArray[0] && status === 'OK') {
      let result = resultArray[0]
      this.props.change('address', result.formatted_address)
      this.assignAddressFormElements(result)
    } else {
      let locationParts = {}
      this.props.change('locationParts', locationParts)
    }
  }

  assignAddressFormElements(result) {
    let streetNumber =
      AreaActions.getGoogleJsonType(result.address_components, 'street_number') || ''

    let streetName = AreaActions.getGoogleJsonType(result.address_components, 'route')

    let city =
      AreaActions.getGoogleJsonType(result.address_components, 'administrative_area_level_3') ||
      AreaActions.getGoogleJsonType(result.address_components, 'locality') ||
      AreaActions.getGoogleJsonType(result.address_components, 'postal_town') ||
      AreaActions.getGoogleJsonType(result.address_components, 'sublocality') ||
      AreaActions.getGoogleJsonType(result.address_components, 'sublocality_level_1')

    let country = AreaActions.getGoogleJsonType(result.address_components, 'country', true) || 'FI'
    let postal_code = AreaActions.getGoogleJsonType(result.address_components, 'postal_code')

    let area = null

    if (postal_code) {
      if (postal_code === '00101') {
        postal_code = '00100'
      }

      this.setState({ postalCode: postal_code })
    }

    let location = {}

    if (result && result.geometry && result.geometry.location) {
      location.lat = result.geometry.location.lat()
      location.lng = result.geometry.location.lng()
    }

    let locationParts = {}
    locationParts.streetNumber = streetNumber || ''
    locationParts.streetName = streetName || ''
    locationParts.postalCode = postal_code || ''
    locationParts.city = city || ''
    locationParts.country = country || ''

    this.props.change('locationParts', locationParts)
    this.props.change('area', area?.id)
    this.props.change('geolocation', location)
    this.props.change('location', result.formatted_address)

    this.getAreaPrice(postal_code, 1)
  }

  updateAreaWithPostalCode(postal_code, country) {
    let areas = this.props.area.area

    for (let area of areas) {
      if (area.postalcode === postal_code && area.country === country) {
        return area
      }
    }
  }

  _handleSizeChange(e) {
    let target = e.currentTarget
    this.setState({ size: target.value })
  }

  removeImage(name) {
    this.props.change(name, '')
  }

  getAreaPrice(postalCode, houseType) {
    houseType = 1
    this.props.getAreaPrice(postalCode + '-fi', houseType)
  }

  _handleDelete() {
    if (confirm(translate('common.areYouSure'))) {
      let lastWatcher = this.props.property.lastProperty

      if (this.props.deleteItem) {
        this.disableDirtyCheck()
        this.props.deleteItem(lastWatcher.id)
      }
    }
  }

  _renderPotentialBuyers() {
    let matches = null
    let potentialBuyer = null

    let info = (
      <Alert iconSrc={iconAvatar}>
        <p>{translate('dashboard.property.potentialBuyersTextWhenWeHaveThem')}</p>
      </Alert>
    )

    let count = 0

    if ((this.props.watcher?.watchResults?.users || []).length !== 0) {
      let users = this.props.watcher.watchResults.users

      if (users.length > 0) {
        count = users.length
        info = (
          <Alert iconSrc={iconAvatar}>
            <p>{translate('dashboard.property.potentialBuyersSaveAndSeeResults')}</p>
          </Alert>
        )
      }

      potentialBuyer = (
        <StatBox
          style={{ marginBottom: '2rem' }}
          title={count + ''}
          description={translate('dashboard.property.potentialBuyers')}
        />
      )
    }

    matches = (
      <div>
        {potentialBuyer}
        {info}
      </div>
    )

    return matches
  }

  handlePublicForSaleChange(formProp) {
    var formValues = this.props.formValues || {}
    var setPrivate = formValues.private
    var setForSale = formValues.isForSale

    if (formProp === 'private') {
      setPrivate = true
      setForSale = false
    } else if (formProp === 'publicNotForSale') {
      setPrivate = false
      setForSale = false
    } else if (formProp === 'publicAndForSale') {
      setPrivate = false
      setForSale = true
    }

    this.props.change('private', setPrivate)
    this.props.change('isForSale', setForSale)
  }

  handleSubmit(e) {
    e.preventDefault()
    let error
    let fv = this.props.formValues

    if (!fv.price) {
      error = translate('error.housePrice')
    }
    if (!fv.size > 0) {
      error = translate('error.houseSize')
    }
    if (!fv.houseType) {
      error = translate('error.houseType')
    }
    if (!fv.locationParts) {
      error = translate('error.address')
    } else {
      if (!fv.locationParts.streetNumber) {
        error = translate('error.streetNumber')
      }
    }
    if (!error) {
      if (this.props.handleSubmit) {
        this.disableDirtyCheck()
        this.props.handleSubmit(e)
      }
    }
    this.setState({ error: error })
  }

  render() {
    let { isNew, property } = this.props

    let myProperties = property?.myProperties || []
    let lastProperty = property?.lastProperty || null

    let formValues = {}

    const hasLicense = hasPaidLicense(this.props.license.userLicenses)
    let canAddProperty = true

    if (isNew && !hasLicense && myProperties.length > 0) {
      canAddProperty = false
    }

    let errorMsg

    if (this.state.error) {
      errorMsg = (
        <div className="extraMarginForGenericForms">
          <ErrorMessage>{this.state.error}</ErrorMessage>
        </div>
      )
    }

    let deleteButton = null

    if (lastProperty?.id) {
      deleteButton = (
        <p>
          <a href="#" style={{ color: Color.red }} onClick={this._handleDelete}>
            {translate('common.deleteProperty')}
          </a>
        </p>
      )
    }

    formValues.houseType = this.props.houseType
    formValues.price = this.props.price
    formValues.size = parseFloat(string2number(this.props.size))
    formValues.houseVisible = this.props.houseVisible

    let size = parseFloat(string2number(this.props.size))
    let priceAverageCurrency = roundPrice(250000, -3, false)

    if (get(this, 'props.area.areaPrice[0].data')) {
      let lowPrice = this.props.area.areaPrice[0].data?.price10
      let highPrice = this.props.area.areaPrice[0].data?.price90

      if (lowPrice && highPrice && size) {
        priceAverageCurrency = roundPrice(((lowPrice + highPrice) / 2) * size, -3, false)
      }
    }

    let imagesJSX = []
    let imageIndex = 0

    let images = this.props.formValues?.image || []

    for (let img of images) {
      if (img) {
        imagesJSX.push(
          <Field
            key={imageIndex}
            name={`image[${imageIndex}]`}
            component={UploadImageField}
            setFieldValue={this.props.change}
            type="file"
            removeImage={this.removeImage}
            required={false}
            isSquare={true}
          />
        )

        imageIndex++
      }
    }

    imagesJSX.push(
      <Field
        key={'property-img-' + imageIndex}
        name={`image[${imageIndex}]`}
        component={UploadImageField}
        setFieldValue={this.props.change}
        type="file"
        removeImage={this.removeImage}
        required={false}
        isSquare={true}
      />
    )

    return (
      <Wrapper>
        <div>
          {!canAddProperty && (
            <GetLicenseOverlay
              backUrl={'/property'}
              message={translate('dashboard.property.noLicense')}
            />
          )}
          <Field
            forwardRef={true}
            withRef={true}
            ref={(ref) => (this.addressInput = ref)}
            id="address"
            name="address"
            className={`input-address ${
              this.props.formValues && this.props.formValues.addressIsValid
                ? 'addressIsValid'
                : 'addressIsInvalid'
            }`}
            label={translate('house.myApartmentAddress')}
            placeholder={translate('house.AddressInput')}
            component={InputField}
            unit={
              <span
                className={`icon-indicator ${
                  this.props.formValues && this.props.formValues.addressIsValid
                    ? 'addressIsValid '
                    : 'addressIsInvalid'
                }`}>
                {this.props.formValues && this.props.formValues.addressIsValid ? (
                  <i className="fa fa-check" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-exclamation" aria-hidden="true"></i>
                )}
              </span>
            }
            type="text"
            onBlur={this.doSearch}
            required={true}
          />

          <form onSubmit={this.handleSubmit}>
            <Field
              name="location"
              label={translate('house.Address')}
              component={InputField}
              type="hidden"
            />
            <Field
              name="area"
              label={translate('house.Address')}
              component={InputField}
              type="hidden"
            />

            <label>{translate('house.Type')}</label>
            <RadioWrapper>
              {this.props.housetype.housetypes.map((houseType) => (
                <Field
                  key={`housetype_${houseType.name}`}
                  name="houseType"
                  width="50%"
                  value={houseType.id}
                  label={translate('houseType.' + houseType.name)}
                  component={RadioField}
                  type="radio"
                  required={false}
                />
              ))}
            </RadioWrapper>

            <Field
              style={{ maxWidth: 160 }}
              name="size"
              label={translate('house.myApartmentSize')}
              placeholder={translate('common.placeholderSize')}
              unit="m²"
              component={InputField}
              type="text"
              onBlur={this._handleSizeChange}
              required={true}
            />
            <label>{translate('house.estimatedPrice')}</label>
            <Field
              name="price"
              label={translate('house.estimatedPrice')}
              placeholder={translate('common.placeholderStart') + priceAverageCurrency}
              unit="€"
              validate={(value) => {
                let val = string2number(value)
                return !val || val < 100 ? translate('error.noPrice') + ' 100' : undefined
              }}
              showLabel={false}
              component={InputField}
              type="text"
              thousands={true}
              required={true}
            />

            <Spacer multiplier={2} />

            <Collapsible open={true}>
              <Field
                name="headerImage"
                placeholder=""
                unit=""
                component={UploadImageField}
                setFieldValue={this.props.change}
                type="file"
                removeImage={this.removeImage}
                required={false}
                addImageText={translate('common.addHeaderImage')}
                removeImageText={translate('common.removeHeaderImage')}
              />

              <Spacer />
              <ImagesWrapper>{imagesJSX}</ImagesWrapper>
              <Spacer />

              <Notice>{translate('common.imageRequirements')}</Notice>

              {this.state.imageUploadError && <Alert notice>{this.state.imageUploadError}</Alert>}

              <Field
                name="description"
                label={translate('house.TellAboutHouse')}
                placeholder=""
                component={TextArea}
                type="text"
                required={false}
              />

              <Spacer />
              <label>{translate('house.externalurl')}</label>
              <p>{translate('house.externalurltext')}</p>
              <Field
                id="externalurl"
                name="externalurl"
                className={`input-externalurl`}
                placeholder="https://"
                component={InputField}
                unit={
                  <span>
                    <i className="fa fa-link" aria-hidden="true"></i>
                  </span>
                }
                type="text"
                required={false}
              />
            </Collapsible>
            <Spacer multiplier={2} />

            <PublicStatusWrapper>
              <h3>{translate('house.SetPublicHeader')}</h3>
              <p>{translate('house.SetPublicText')}</p>
              <Field
                name="private"
                value="1"
                label={translate('house.private')}
                component={ToggleField}
                onClick={() => this.handlePublicForSaleChange('private')}
                checked={!this.props.formValues?.isForSale && !!this.props?.formValues?.private}
                required={false}
              />
              <Field
                name="private"
                value="1"
                label={translate('house.publicNotForSale')}
                component={ToggleField}
                onClick={() => this.handlePublicForSaleChange('publicNotForSale')}
                checked={!this.props.formValues?.isForSale && !this.props?.formValues?.private}
                required={false}
              />
              <Field
                name="isForSale"
                value="1"
                label={translate('house.publicAndForSale')}
                component={ToggleField}
                onClick={() => this.handlePublicForSaleChange('publicAndForSale')}
                checked={!!this.props.formValues?.isForSale && !this.props?.formValues?.private}
                required={false}
              />
            </PublicStatusWrapper>
            <Spacer />
            {!canAddProperty && !lastProperty && (
              <div>
                <Spacer />
                <Alert notice>
                  <p>{translate('dashboard.property.noLicense')}</p>
                </Alert>
                <Spacer />
              </div>
            )}
            {errorMsg}
            <ColWrapper>
              <Spacer />
              <Button
                disabled={!canAddProperty && !lastProperty}
                text={translate('house.Submit')}
                type="submit"
              />
              <Spacer />
              <Button
                text={translate('common.cancel')}
                type="button"
                layout="link"
                onClick={() => {
                  changePage('/property')
                }}
              />
              <Spacer multiplier={2} />
              {deleteButton}
            </ColWrapper>
          </form>
        </div>
        {/* end of leftParagraph */}
        {/* start of rightParagraph */}
        {this._renderPotentialBuyers()}
        {/* end of rightParagraph */}
      </Wrapper>
    )
  }
}

PropertyAdd = reduxForm({
  form: 'propertyadd'
})(PropertyAdd)

const selector = formValueSelector('propertyadd') // <-- same as form name

PropertyAdd = connect((state) => {
  // can select values individually
  const houseType = selector(state, 'houseType')
  const size = selector(state, 'size')
  const price = selector(state, 'price')
  const houseVisible = selector(state, 'houseVisible')
  const locationParts = selector(state, 'locationParts')

  let formValues = state.form.propertyadd?.values || {}

  if (
    locationParts &&
    locationParts.streetNumber &&
    locationParts.streetName &&
    locationParts.postalCode &&
    locationParts.city &&
    locationParts.country
  ) {
    formValues.addressIsValid = true
  }

  let updateId = Object.values(formValues).join('_')
  let initialValues = state.form.propertyadd?.initial
  let isDirty = initialValues && getObjectHash(formValues) !== getObjectHash(initialValues)

  return {
    houseType,
    size,
    price,
    houseVisible,
    formValues,
    isDirty,
    locationParts,
    updateTime: updateId,
    license: state.license
  }
})(withRouter(PropertyAdd))

export default PropertyAdd
