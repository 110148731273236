import React, { Component } from 'react'
import styled from 'styled-components/macro'

import Color from '../../styles/color'
import Variables from '../../styles/variables'

const RadioWrapper = styled.div`
  width: calc(${(props) => (props.width ? props.width : '100%')} - ${Variables.gutter});
  display: block;
  margin: ${Variables.gutterHalf};
  text-align: left;
  position: relative;
  background: ${Color.white};
  box-sizing: border-box;
  border-radius: ${Variables.borderRadius};

  > label {
    border: 0.1rem solid ${(props) => (props.checked ? Color.green : Color.border)};
    border-radius: ${Variables.borderRadius};
    padding: 1.5rem 5rem 1.5rem 2rem;
    cursor: pointer;
    width: 100%;
    display: block;
    font-weight: ${(props) => (props.checked ? '700' : '400')};
    margin: 0;
    font-size: inherit;
    text-transform: none;
    position: relative;
    z-index: 3;

    > input {
      display: none;
    }
  }

  > div {
    position: absolute;
    border-radius: 50%;
    right: 1.5rem;
    top: 50%;
    margin-top: -1.2rem;
    width: 2.4rem;
    height: 2.4rem;
    border: 0.1rem solid ${Color.border};
    z-index: 1;

    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      margin-top: -0.8rem;
      margin-left: -0.8rem;
      width: 1.6rem;
      height: 1.6rem;
      background-color: ${(props) => (props.checked ? Color.green : 'transparent')};
    }
  }
`

export default class RadioField extends Component {
  render() {
    const props = this.props

    return (
      <RadioWrapper width={props.width} checked={props.input.checked}>
        <label>
          {props.label}
          <input ref="radio" {...props.input} placeholder={props.placeholder} type={props.type} />
        </label>
        <div>
          <div></div>
        </div>
      </RadioWrapper>
    )
  }
}
