import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import translate from 'counterpart'
import styled, { css } from 'styled-components/macro'

import Color from '../../styles/color'
import Variables from '../../styles/variables'

import Button from '../shared/Button'
import ErrorMessage from '../shared/ErrorMessage'
import InputField from '../shared/InputField'
import TextArea from '../shared/TextArea'
import Spacer from '../shared/Spacer'
import RadioField from '../shared/RadioField'
import StatBox from '../shared/StatBox'
import Alert from '../shared/Alert'
import infoHouse from '../../images/info-house.svg'

import { changePage } from '../../actions/PageActions'
import { saveTemporaryWatcher } from '../../actions/SearchWatcherActions'
import SearchWatcherMap from './SearchWatcherMap'
import { isEqual, compact, omit } from 'lodash'
import { numVal } from '../../utils/number'
import { hasPaidLicense } from '../../actions/LicenseActions'
import { ColumnWrapper, LeftColumn, RightColumn, ResultsWrapper } from './Columns'
import RangeInput from '../shared/RangeInput'
import { SuperScript } from '../shared/Typography'
import PropertyBlock from '../property/items/PropertyBlock'
import { MAX_CIRCLES } from '../../constants'
import GetLicenseOverlay from '../shared/GetLicenseOverlay'
import { withRouter } from 'react-router'
import { getObjectHash } from '../../utils/getObjectHash'

const Wrapper = styled.div`
  position: relative;

  @media (min-width: ${Variables.bpSmall}) {
    padding-top: ${Variables.gutter};
  }
`

const NotInMobile = styled.div`
  display: none;

  @media (min-width: ${Variables.bpSmall}) {
    display: block;
  }
`

const SearchMapWrapper = styled.div`
  position: static;
  width: calc(100% + (${Variables.gutter} * 2));
  margin-left: -${Variables.gutter};
  height: 0;
  z-index: 100;
  pointer-events: none;
  transition: all 0.4s ease-out;
  opacity: 0;

  @media (min-width: ${Variables.bpSmall}) {
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc(50% + 1rem);
    height: auto;
  }

  ${(p) =>
    p.visible
      ? css`
          pointer-events: all;
          opacity: 1;
          height: calc(100vh - ${Variables.navigationHeight} - ${Variables.backHeaderHeight} - 3px);

          @media (min-width: ${Variables.bpSmall}) {
            left: 0;
            right: auto;
          }
        `
      : ''}
`

const RadioWrapper = styled.div`
  margin: -1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`

const RangeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > span {
    display: block;
    margin-bottom: ${Variables.gutterHalf};
  }
`

const FieldWrapper = styled.div`
  padding: 1rem 0;

  &:first-child {
    padding-top: 2rem;
  }

  &:last-child {
    padding-bottom: 2rem;
  }
`

const ColWrapper = styled.div`
  width: 25rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`

const ResultCountWrapper = styled.div`
  margin-bottom: 1.5rem;

  > div {
    margin-bottom: ${Variables.gutter};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const ShowMapWrapper = styled.div`
  margin: ${Variables.gutter};
  text-align: center;
`

let delayTimerFormUpdate

class SearchWatcherAdd extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fileUploadTime: 0,
      mapCircles: [], // {lat, long, radius}[]
      showMap: false,
      initialized: false
    }

    this.fetchPotentialProperties = this.fetchPotentialProperties.bind(this)
    this.checkWatcher = this.checkWatcher.bind(this)
    this.setCircles = this.setCircles.bind(this)
    this.toggleMap = this.toggleMap.bind(this)
    this._handleDelete = this._handleDelete.bind(this)
    this._renderPotentialHouses = this._renderPotentialHouses.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  static propTypes = {
    onSaveTemporaryWatcher: PropTypes.func
  }

  componentDidMount() {
    this.checkWatcher()
    let _this = this

    delayTimerFormUpdate = setTimeout(function () {
      _this.fetchPotentialProperties()
    }, 2000)

    if (this.props.router && this.props.route) {
      this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave)

      window.onbeforeunload = (e) => {
        let unsavedChangesPrompt = this.routerWillLeave()

        if (unsavedChangesPrompt) {
          e.returnValue = unsavedChangesPrompt
        }
      }
    }
  }

  routerWillLeave = () => {
    if (this.isDirty()) {
      return translate('common.changesNotSaved')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let props = this.props
    this.checkWatcher()

    if (props.selectionId) {
      if (prevProps.selectionId !== props.selectionId) {
        clearTimeout(delayTimerFormUpdate)

        delayTimerFormUpdate = setTimeout(() => {
          this.fetchPotentialProperties()
        }, 1000)
      }
    }

    let currentCircles = this.state.mapCircles
    let prevCircles = prevState.mapCircles

    if (!isEqual(currentCircles, prevCircles)) {
      this.props.change('areas', currentCircles)
    }
  }

  fetchPotentialProperties() {
    let watch = this.props.formValues

    if (
      watch &&
      (watch.areas || []).length !== 0 &&
      compact(watch.price).length === 2 &&
      compact(watch.size).length === 2 &&
      watch.houseType
    ) {
      let searchProps = {
        ...omit(watch, 'price', 'size', 'houseType'),
        // Join the houseTypes value to a comma-separated string while skipping the first element.
        houseType: compact(
          watch.houseType.slice(1).map((val, idx) => (val ? `${idx + 1}` : undefined))
        ).join(','),
        minPrice: watch.price[0],
        maxPrice: watch.price[1],
        minSize: watch.size[0],
        maxSize: watch.size[1]
      }

      this.props.getHousesByWatchParams(searchProps)
    }
  }

  getHouseTypeArray(houseTypeArray) {
    return [true, houseTypeArray.split(',').map(() => true)]
  }

  findWatcher = () => {
    let { watcher, watchId, isNew = false } = this.props

    if (isNew) {
      return null
    }

    let watcherState = watcher
    let lastWatcher = null

    // Return the lastWatcher or a watcher matching the ID from own watchers.

    if (
      watcherState?.lastWatcher ||
      (watcherState?.myWatchers && watcherState?.myWatchers?.length === 0)
    ) {
      lastWatcher =
        watcherState?.lastWatcher ||
        // Fall back on fetched own watchers if lastWatcher is not populated.
        // (this is a shitshow, the data should not be loaded like this but here we are)
        (watcherState?.myWatchers || []).find((watcher) => watcher.id === watchId)
    }

    return lastWatcher
  }

  checkWatcher() {
    if (this.state.initialized) {
      return
    }

    if (this.props.isNew) {
      let propertyForm = this.getFormValues()
      this.props.initialize(propertyForm)

      this.toggleMap(true)

      this.setState({
        initialized: true,
        initialValuesHash: getObjectHash(propertyForm)
      })

      return
    }

    let watcher = this.findWatcher()
    let force = false

    if (!watcher) {
      let watcherJSON = localStorage.getItem('tempWatcher')

      if (watcherJSON) {
        watcher = JSON.parse(watcherJSON)
        force = true
        this.props.clearTemporaryWatcher()
      }
    }

    if ((watcher && watcher.id) || force) {
      let propertyForm = this.getFormValues(watcher)
      this.props.initialize(propertyForm)

      let query = new URL(window.location).search
      let openMap = query.includes('open_map=true')

      if (openMap) {
        this.toggleMap(true)
      }

      this.setState({
        initialValuesHash: getObjectHash(propertyForm),
        mapCircles: propertyForm.areas,
        initialized: true
      })
    }
  }

  getFormValues = (watcher = {}) => {
    let propertyForm = {}
    propertyForm.id = watcher.id
    propertyForm.name = watcher.name
    propertyForm.price = [numVal(watcher.minPrice) || 100000, numVal(watcher.maxPrice) || 300000]
    propertyForm.houseType = !watcher.houseType
      ? []
      : [false, ...watcher.houseType.split(',').map(() => true)]
    propertyForm.size = [numVal(watcher.minSize) || 40, numVal(watcher.maxSize) || 200]
    propertyForm.description = watcher.description

    let areas = watcher.areas || []

    if (typeof areas === 'string') {
      try {
        areas = JSON.parse(areas)
      } catch (err) {
        areas = []
      }
    }

    propertyForm.areas = areas
    return propertyForm
  }

  checkDirty = true

  disableDirtyCheck = () => {
    this.checkDirty = false
    window.onbeforeunload = undefined
  }

  isDirty = () => {
    let { initialized } = this.state

    if (!this.checkDirty || !initialized) {
      return false
    }

    return this.props.isDirty
  }

  setCircles(circles) {
    this.setState({
      mapCircles: circles.slice(0, MAX_CIRCLES)
    })
  }

  renderHouseTypes(housetypes) {
    let housetypesElems = []

    if (housetypes) {
      for (let houseType of housetypes.housetypes) {
        if (!houseType) {
          continue
        }

        let elem = (
          <Field
            key={`housetype_${houseType.id}`}
            name={`houseType[${houseType.id}]`}
            width="50%"
            value={houseType.id}
            label={translate('houseType.' + houseType.name)}
            component={RadioField}
            type="checkbox"
            required={false}
          />
        )
        housetypesElems.push(elem)
      }
    }
    return housetypesElems
  }

  canAddSearchWatcher = () => {
    const hasLicense = hasPaidLicense(this.props.license.userLicenses)
    let canAddSearchWatch = true

    if (this.props.isNew && !hasLicense && (this.props.watcher?.myWatchers || []).length > 0) {
      canAddSearchWatch = false
    }

    return canAddSearchWatch
  }

  toggleMap(setValue) {
    let showMap = typeof setValue === 'undefined' ? !this.state.showMap : setValue
    let canAddWatcher = this.canAddSearchWatcher()

    if (!canAddWatcher) {
      this.setState({ showMap: false })
      return
    }

    if (window.matchMedia(`(max-width: calc(${Variables.bpSmall} - 1px))`).matches) {
      if (showMap) {
        document.body.style.overflow = 'hidden'
        document.body.style.height = '100vh'
        window.scrollTo({ top: 0 })
      } else {
        document.body.style.overflow = 'auto'
        document.body.style.height = 'auto'
        window.scrollTo({ top: 0 })
      }
    }

    this.setState({ showMap })
  }

  _handleDelete() {
    if (confirm(translate('common.areYouSure'))) {
      let lastWatcher = this.props.watcher.lastWatcher
      if (this.props.deleteItem) {
        this.disableDirtyCheck()
        this.props.deleteItem(lastWatcher.id)
      }
    }
  }

  _renderPotentialHouses() {
    let results = []

    if (this.props.watcher && this.props.watcher.watchResults) {
      results = this.props.watcher.watchResults
    }

    let count = results.length

    let info = (
      <Alert iconSrc={infoHouse}>
        <p>{translate('dashboard.searchWatcher.potentialPropertiesTextWhenWeHaveThem')}</p>
      </Alert>
    )

    let stats = (
      <StatBox
        style={{ marginLeft: '-0.5rem', marginRight: '-0.5rem' }}
        title={count + ''}
        description={translate('dashboard.property.potentialProperties')}
      />
    )

    return (
      <ResultCountWrapper>
        {stats}
        {results.length === 0 && info}
      </ResultCountWrapper>
    )
  }

  handleSubmit(e) {
    e.preventDefault()
    let error
    let fv = this.props.formValues

    if (!fv.name) {
      error = translate('error.name')
    }
    if (!fv.houseType || !fv.houseType.length > 0) {
      error = translate('error.houseType')
    }
    if (!fv.areas || fv.areas.length === 0) {
      error = translate('error.noArea')
    }
    if (!fv.description || !fv.description.length > 0) {
      error = translate('error.description')
    }
    if (!error) {
      if (!this.props.auth) {
        // not logged in - save temp watcher
        this.props.onSaveTemporaryWatcher(fv)
      }

      this.disableDirtyCheck()
      this.props.handleSubmit()
    }

    this.setState({ error: error })
  }

  mapWrapperRef = null

  render() {
    let errorMsg

    if (this.state.error) {
      errorMsg = (
        <div className="extraMarginForGenericForms">
          <ErrorMessage>{this.state.error}</ErrorMessage>
        </div>
      )
    }

    let watcher = this.findWatcher()
    let canAddSearchWatch = this.canAddSearchWatcher()

    let houseTypeElements = this.renderHouseTypes(this.props.housetype)

    let showMapButton = (
      <ShowMapWrapper>
        <Button
          text={translate('common.addFromMap')}
          type="button"
          layout="outline"
          onClick={() => this.toggleMap()}
        />
      </ShowMapWrapper>
    )

    let deleteButton = null

    if (watcher && watcher.id) {
      deleteButton = (
        <p>
          <a href="#" style={{ color: Color.red }} onClick={this._handleDelete}>
            {translate('common.deleteWatcher')}
          </a>
        </p>
      )
    }

    let potentialHouses = this._renderPotentialHouses()

    return (
      <Wrapper>
        {!canAddSearchWatch && (
          <GetLicenseOverlay
            backUrl={'/find'}
            message={translate('dashboard.searchWatcher.noLicense')}
          />
        )}
        <SearchMapWrapper ref={(ref) => (this.mapWrapperRef = ref)} visible={this.state.showMap}>
          <SearchWatcherMap
            circles={this.state.mapCircles}
            onDone={(circles) => {
              if (circles && circles.length !== 0) {
                this.setCircles(circles)
              }

              this.toggleMap(false)
            }}
            maxCircles={MAX_CIRCLES}
          />
        </SearchMapWrapper>
        <ColumnWrapper>
          <LeftColumn>
            <form onSubmit={this.handleSubmit}>
              <label>{translate('watcher.name')}</label>
              <Field
                name="name"
                label={translate('watcher.name')}
                unit=""
                showLabel={false}
                component={InputField}
                type="text"
                required={true}
              />

              {showMapButton}

              <label>{translate('house.Type')}</label>
              <RadioWrapper>{houseTypeElements}</RadioWrapper>

              <div>
                <FieldWrapper>
                  <label>{translate('house.apartmentSize')}</label>
                  <RangeWrapper>
                    <Field
                      name="size"
                      label={translate('house.apartmentSize')}
                      placeholder={translate('common.placeholderStart') + ' 50'}
                      unit=""
                      showLabel={false}
                      component={RangeInput}
                      props={{
                        defaultValue: [40, 200],
                        pushable: 10,
                        allowCross: false,
                        min: 10,
                        max: 1000,
                        step: 10,
                        unit: (
                          <>
                            m<SuperScript>2</SuperScript>
                          </>
                        ),
                        subLabel: translate('house.squareMeters')
                      }}
                    />
                  </RangeWrapper>
                </FieldWrapper>

                <FieldWrapper>
                  <label>{translate('house.priceRange')}</label>
                  <RangeWrapper>
                    <Field
                      name="price"
                      label={translate('house.price')}
                      placeholder={translate('common.placeholderStart') + ' 150000'}
                      unit=""
                      showLabel={false}
                      component={RangeInput}
                      props={{
                        defaultValue: [100000, 300000],
                        pushable: 10000,
                        allowCross: false,
                        min: 1000,
                        max: 2500000,
                        step: 1000,
                        unit: '€',
                        subLabel: translate('house.priceRange')
                      }}
                      thousands={true}
                    />
                  </RangeWrapper>
                </FieldWrapper>
              </div>

              <Field
                name="description"
                label={translate('searchWatcher.TellAboutWhatYouSearch')}
                placeholder=""
                unit=""
                component={TextArea}
                type="text"
                required={false}
              />

              {!canAddSearchWatch && !watcher && (
                <>
                  <Spacer />
                  <Alert notice>
                    <p>{translate('dashboard.searchWatcher.noLicense')}</p>
                  </Alert>
                  <Spacer />
                </>
              )}

              {errorMsg}
              <ColWrapper>
                <Spacer />
                <Button
                  disabled={!canAddSearchWatch && !watcher}
                  text={translate('watcher.Submit')}
                  type="submit"
                />
                <Spacer />
                <Button
                  text={translate('common.cancel')}
                  type="button"
                  layout="link"
                  onClick={(e) => {
                    changePage('/find')
                  }}
                />
                <Spacer multiplier={2} />
                {deleteButton}
              </ColWrapper>
            </form>
          </LeftColumn>
          <RightColumn>
            <NotInMobile>{potentialHouses}</NotInMobile>
            <ResultsWrapper>
              {(this.props.watcher?.watchResults || []).map((property) => {
                if (!property || !property.id) {
                  return null
                }

                return (
                  <PropertyBlock
                    key={`property_${property.id}`}
                    property={property}
                    houseType={this.props.housetype}
                  />
                )
              })}
            </ResultsWrapper>
          </RightColumn>
        </ColumnWrapper>
      </Wrapper>
    )
  }
}

SearchWatcherAdd = reduxForm({
  form: 'watcheradd'
})(SearchWatcherAdd)

SearchWatcherAdd = connect((state) => {
  let formValues = state.form.watcheradd?.values || {}
  let initialValues = state.form.watcheradd?.initial
  let isDirty = initialValues && getObjectHash(formValues) !== getObjectHash(initialValues)

  let selectionId = Object.values(formValues).join('_')

  return {
    formValues,
    isDirty,
    selectionId
  }
})(SearchWatcherAdd)

const mapDispatchToProps = (dispatch) => ({
  onSaveTemporaryWatcher: (data) => dispatch(saveTemporaryWatcher(data))
})

export default connect(
  (state) => ({
    license: state.license
  }),
  mapDispatchToProps
)(withRouter(SearchWatcherAdd))
