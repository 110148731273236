import translate from 'counterpart'
import React from 'react'
import { REACT_APP_AUTH_WEB_PATH } from '../../constants'

const LoginButton = ({
  label = translate('form.login.login'),
  isButton,
  redirectTo = location.pathname
}) => {
  return (
    <a
      {...{ href: isButton ? undefined : '#' }}
      style={{ textDecoration: 'none' }}
      href={`${REACT_APP_AUTH_WEB_PATH}?redirectToPath=${redirectTo}`}>
      {label}
    </a>
  )
}

export default LoginButton
