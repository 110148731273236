import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import moment from 'moment'

import PropertyFrontpage from '../components/property/PropertyFrontpage'
import PropertyAdd from '../components/property/PropertyAdd'
import PropertyView from '../components/property/PropertyView'
import ExploreView from '../components/property/ExploreView'

import SearchWatcherFrontpage from '../components/searchWatcher/SearchWatcherFrontpage'
import SearchWatcherAdd from '../components/searchWatcher/SearchWatcherAdd'
import * as HouseActions from '../actions/HouseActions'
import {
  getFeaturedByComments,
  getFeaturedByArea,
  getFeaturedByMostLiked
} from '../actions/HouseActions'
import * as SearchWatcherActions from '../actions/SearchWatcherActions'
import * as HouseTypeActions from '../actions/HouseTypeActions'
import * as FavoriteActions from '../actions/FavoriteActions'
import * as AreaActions from '../actions/AreaActions'
import * as MessageActions from '../actions/MessageActions'
import FavouritesFrontpage from '../components/property/FavouritesFrontpage'
import cookie from 'react-cookies'

import translate from 'counterpart'
import LoggedInContainer from './LoggedInContainer'
import LoggedOutContainer from './LoggedOutContainer'
import Variables from '../styles/variables'
import { GoogleMapProvider } from '../components/googlemap/GoogleMapProvider'
import { createImageLike, createImageComment, getImageCommentById } from '../actions/CommentActions'
import { createApiRequest } from '../utils/Api'
import { getUserLicenses } from '../actions/LicenseActions'

class PropertyContainer extends Component {
  featured = {
    city: undefined,
    country: undefined,
    timestamp: undefined
  }

  constructor(props) {
    super(props)

    this.state = {
      houseTypeRequested: false,
      toggleRegisterModal: false,
      registerErrors: undefined,
      userPropertiesFetched: false,
      favouritesFetched: false,
      watchersFetched: false,
      creds: undefined
    }

    this.loadProperty = this.loadProperty.bind(this)
    this.refreshSelectedProperty = this.refreshSelectedProperty.bind(this)
    this._unloadProperty = this._unloadProperty.bind(this)
    this._loadWatcherById = this._loadWatcherById.bind(this)
    this._unloadWatcher = this._unloadWatcher.bind(this)
    this.getElementByAction = this.getElementByAction.bind(this)
    this.loadImageCommentsById = this.loadImageCommentsById.bind(this)
    this._postImageComment = this._postImageComment.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this._postImageLike = this._postImageLike.bind(this)
    this._updateImageComments = this._updateImageComments.bind(this)

    this.getFeaturedProperties = this.getFeaturedProperties.bind(this)
    this._getMostHousesArea = this._getMostHousesArea.bind(this)
    this._getMostLikedHouses = this._getMostLikedHouses.bind(this)
    this._getMostCommentedHouses = this._getMostCommentedHouses.bind(this)
  }

  componentDidMount() {
    const { getHouseTypes } = this.props

    if (!this.props.housetype || !this.props.housetype.housetypes) {
      if (!this.state.houseTypeRequested) {
        this.setState({ houseTypeRequested: true })
        getHouseTypes('fi')
      }
    }

    if (this.props.propertyId) {
      this.loadProperty()
    }

    if (this.props.watcherId) {
      this._loadWatcherById(this.props.watcherId)

      if (this.props.route.action === 'searchwatcher' && this.props.watcherId) {
        cookie.save('selectedWatcher', this.props.watcherId, { path: '/' })
      }
    }

    this.loadUserProperties()
  }

  componentDidUpdate(prevProps) {
    let props = this.props

    if (props.propertyId && props.propertyId !== prevProps.propertyId) {
      this.loadProperty()
    } else if (!props.propertyId && props.property.lastProperty) {
      this._unloadProperty()
    }

    if (props.watcherId && props.watcherId !== prevProps.watcherId) {
      this._loadWatcherById(props.watcherId)

      if (props.route.action === 'searchwatcher' && props.watcherId) {
        cookie.save('selectedWatcher', props.watcherId, { path: '/' })
      }
    } else if (!props.watcherId && props.watcher.lastWatcher) {
      this._unloadWatcher()
    }

    this.loadUserProperties()
  }

  loadUserProperties = () => {
    const { auth } = this.props

    if (auth?.user) {
      if (!this.state.userPropertiesFetched) {
        this.setState({ userPropertiesFetched: true })
        this.props.getMyProperties()
      }

      if (!this.state.userLicenseFetched) {
        this.setState({ userLicenseFetched: true })
        this.props.getUserLicenses()
      }

      if (!this.state.watchersFetched) {
        this.setState({ watchersFetched: true })
        this.props.getMyWatchers()
      }

      if (!this.state.favouritesFetched) {
        this.setState({ favouritesFetched: true })
        this.props.getMyFavourites()
      }
    }
  }

  onUpdateProperty = (params, action) => {
    return action(params).then(() => {
      this.refreshSelectedProperty()
    })
  }

  onUpdateWatcher = (params, action) => {
    return action(params).then(() => {
      this.setState({ watchersFetched: false })
    })
  }

  onToggleFavorite = (params) => {
    return this.props.toggleFavorite(params).then(() => {
      this.setState({ favouritesFetched: false })
    })
  }

  loadImageCommentsById(entityId) {
    this.props.getImageCommentById(entityId)
  }

  async _postImageComment(data, successF) {
    if (data && data.entityId) {
      await this.props.createImageComment(data, successF)
      this._updateImageComments()
    } else {
      console.error('Wrong data for image comment', data)
    }
  }

  async _postImageLike(id, value, successF) {
    await this.props.createImageLike(id, value, successF)
    this.refreshSelectedProperty()
  }

  getFeaturedProperties(country, city) {
    let featuredCity = this.featured.city
    let featuredCountry = this.featured.country
    let timestamp = this.featured.timestamp

    if (
      (!featuredCity && !featuredCountry && !timestamp) ||
      featuredCity !== city ||
      featuredCountry !== country ||
      !timestamp ||
      (timestamp && timestamp.diff(moment(), 'seconds') >= 20)
    ) {
      this.featured = { timestamp: moment(), city, country }

      this._getMostCommentedHouses(city, country)
      this._getMostLikedHouses(city, country)
      this._getMostHousesArea(country)
    }
  }

  _getMostCommentedHouses(city, country) {
    let query = ''

    if (city && city !== '-') {
      query += '&city=' + city
    }

    if (country && country !== '-') {
      query += '&country=' + country
    }

    this.props.getFeaturedByComments(query)
  }

  _getMostLikedHouses(city, country) {
    var query = ''

    if (city && city !== '-') {
      query += '&city=' + city
    }

    if (country && country !== '-') {
      query += '&country=' + country
    }

    this.props.getFeaturedByMostLiked(query)
  }

  _getMostHousesArea(country) {
    var query = ''

    if (country && country !== '-') {
      query += '&country=' + country
    }

    this.props.getFeaturedByArea(query)
  }

  _removeComment = async (id) => {
    let apiRequest = createApiRequest()

    let URL = `/comments/?id=${id}`
    let METHOD = 'DELETE'

    let result = await apiRequest({ path: URL, method: METHOD })

    if (result?.success) {
      this._updateImageComments()
    }
  }

  _updateImageComments() {
    var propertyId = this.props.propertyId
    var imageId = this.props.imageId

    if (propertyId && imageId) {
      this.loadImageCommentsById(propertyId + '/' + imageId)
    }
  }

  loadProperty() {
    let { auth, propertyId } = this.props

    if (auth?.user) {
      this.props.getMyFavourites()
      this.setState({ favouritesFetched: true })
    }

    if (propertyId) {
      this.refreshSelectedProperty()
      this.props.getHouseStats(propertyId)
    }
  }

  refreshSelectedProperty() {
    let { propertyId } = this.props

    if (propertyId) {
      this.props.getPropertyById(propertyId)
    }
  }

  _unloadProperty() {
    this.props.unloadProperty()
  }

  _loadWatcherById(id) {
    this.props.getWatcherById(id)
  }

  _unloadWatcher() {
    this.props.unloadWatcher()
  }

  closeModal() {
    this.setState({ toggleRegisterModal: false })
  }

  getElementByAction(props) {
    const {
      auth,
      createProperty,
      route,
      housetype,
      property,
      area,
      createWatcher,
      getMyProperties,
      getAreaPrice,
      getMyWatchers,
      watcher,
      getWatchResults,
      getMyFavourites,
      favourites,
      getWatchesByHouse,
      deleteWatcher,
      deleteProperty,
      clearTemporaryWatcher,
      getWatcherByParams,
      getHousesByWatchParams,
      sendMessage,
      getHiddenHousesByWatchId,
      getHouseStats,
      messages,
      imageId,
      comments,
      license
    } = props

    if (!housetype.housetypes) {
      return null
    }

    if (!area) {
      return null
    }

    let view = route.action
    let backUrl = '/property'

    if (view === 'explore-property-view') {
      view = 'property-view'
      backUrl = '/explore/' + this.props.country + '/' + this.props.city
    }

    switch (view) {
      case 'explore':
        return (
          <LoggedInContainer
            navigationRoute={route || null}
            hideNavigation={false}
            property={property}
            showFavourites={true}
            dth="page"
            selected="explore"
            maxWidth={'none'}>
            <ExploreView
              me={auth.user}
              property={property}
              housetype={housetype}
              auth={auth}
              sendMessage={sendMessage}
              messagesReducer={messages}
              resetMessagesSent={this.props.resetMessagesSent}
              getFeatured={this.getFeaturedProperties}
              country={this.props.country}
              city={this.props.city}
              command={this.props.command}
            />
          </LoggedInContainer>
        )
      case 'view':
        let viewMaxWidth = 'col'
        let customTopPadding

        if ((property?.myProperties || []).length !== 0) {
          viewMaxWidth = 'page'
          customTopPadding = Variables.gutter
        }

        return (
          <LoggedInContainer
            hideNavigation={false}
            navigationRoute={route || null}
            selected="property"
            maxWidth={viewMaxWidth}
            topPadding={customTopPadding}>
            <PropertyFrontpage
              getWatchResults={getWatchesByHouse}
              getMyProperties={getMyProperties}
              property={property}
              watcher={watcher}
              auth={auth}
              sendMessage={sendMessage}
              getAreaPrice={getAreaPrice}
              area={area}
              getHouseStats={getHouseStats}
              housetype={housetype}
              comments={comments}
            />
          </LoggedInContainer>
        )
      case 'property-add':
        return (
          <LoggedInContainer
            hideNavigation={false}
            navigationRoute={route || null}
            selected="property"
            backUrl="/property"
            customTitle={translate('pageHeaders.addProperty')}
            maxWidth="page">
            <PropertyAdd
              isNew={true}
              property={property}
              license={license}
              getAreaPrice={getAreaPrice}
              housetype={housetype}
              onSubmit={(params) => this.onUpdateProperty(params, createProperty)}
              area={area}
              auth={auth}
              getWatcherByParams={getWatcherByParams}
              watcher={watcher}
              route={route}
            />
          </LoggedInContainer>
        )
      case 'property-edit':
        return (
          <LoggedInContainer
            hideNavigation={false}
            navigationRoute={route || null}
            selected="property"
            backUrl="/property"
            customTitle={translate('pageHeaders.editProperty')}
            maxWidth="page">
            <PropertyAdd
              getAreaPrice={getAreaPrice}
              housetype={housetype}
              property={property}
              deleteItem={(params) => this.onUpdateProperty(params, deleteProperty)}
              onSubmit={(params) => this.onUpdateProperty(params, createProperty)}
              area={area}
              auth={auth}
              getWatcherByParams={getWatcherByParams}
              watcher={watcher}
              route={route}
            />
          </LoggedInContainer>
        )
      case 'property-view':
        if (auth?.user) {
          return (
            <LoggedInContainer
              navigationRoute={route || null}
              selected="explore"
              hideNavigation={false}
              backUrl={backUrl}
              backLinkText={translate('pageHeaders.backToListing')}
              favourites={favourites}
              property={property}
              showFavourites={true}
              maxWidth="page">
              <PropertyView
                me={auth.user}
                housetype={housetype}
                property={property}
                auth={auth}
                sendMessage={sendMessage}
                country={this.props.country}
                city={this.props.city}
                toggleFavorite={this.onToggleFavorite}
                favourites={favourites}
                messagesReducer={messages}
                resetMessagesSent={this.props.resetMessagesSent}
                imageId={imageId}
                comments={comments}
                getComments={this.loadImageCommentsById}
                postComment={this._postImageComment}
                postLike={this._postImageLike}
                refreshProperty={this.refreshSelectedProperty}
                removeComment={this._removeComment}
              />
            </LoggedInContainer>
          )
        } else {
          return (
            <LoggedOutContainer
              navigationRoute={this.props.route}
              backUrl={backUrl}
              backLinkText={translate('pageHeaders.backToListing')}
              hideNavigation={false}
              selected="property"
              maxWidth="page">
              <PropertyView
                me={auth.user}
                housetype={housetype}
                property={property}
                auth={auth}
                sendMessage={sendMessage}
                favourites={favourites}
                messagesReducer={messages}
                resetMessagesSent={this.props.resetMessagesSent}
                imageId={imageId}
                comments={comments}
                getComments={this.loadImageCommentsById}
                postComment={this._postImageComment}
                postLike={this._postImageLike}
                country={this.props.country}
                city={this.props.city}
                refreshProperty={this.refreshSelectedProperty}
              />
            </LoggedOutContainer>
          )
        }
      case 'searchwatcher':
        if (auth?.user) {
          let viewMaxWidth = 'col'
          let customTopPadding

          if (Array.isArray(watcher.myWatchers) && watcher.myWatchers.length > 0) {
            viewMaxWidth = 'page'
            customTopPadding = Variables.gutter
          }

          return (
            <LoggedInContainer
              navigationRoute={route || null}
              selected="property"
              maxWidth={viewMaxWidth}
              topPadding={customTopPadding}>
              <SearchWatcherFrontpage
                getWatchResults={getWatchResults}
                getMyWatchers={getMyWatchers}
                watcher={watcher}
                auth={auth}
                getHiddenHousesByWatchId={getHiddenHousesByWatchId}
                housetype={housetype}
              />
            </LoggedInContainer>
          )
        } else {
          return (
            <LoggedInContainer navigationRoute={route || null} selected="property">
              <SearchWatcherAdd
                housetype={housetype}
                area={area}
                onSubmit={(creds) => {
                  if (auth?.user && auth?.user?.email_verified) {
                    createWatcher(creds)
                  } else {
                    this.setState({ toggleRegisterModal: true, creds: creds })
                  }
                }}
                auth={null}
                clearTemporaryWatcher={clearTemporaryWatcher}
                getHousesByWatchParams={getHousesByWatchParams}
                watcher={null}
              />
            </LoggedInContainer>
          )
        }
      case 'searchwatcher-add':
        return (
          <LoggedInContainer
            hideNavigation={false}
            navigationRoute={route || null}
            selected="property"
            backUrl="/find"
            customTitle={translate('pageHeaders.addWatch')}
            maxWidth="page">
            <SearchWatcherAdd
              isNew={true}
              watchId={this.props.watcherId}
              housetype={housetype}
              area={area}
              onSubmit={(params) => this.onUpdateWatcher(params, createWatcher)}
              auth={auth}
              clearTemporaryWatcher={clearTemporaryWatcher}
              getHousesByWatchParams={getHousesByWatchParams}
              watcher={watcher}
              route={route}
            />
          </LoggedInContainer>
        )
      case 'searchwatcher-edit':
        return (
          <LoggedInContainer
            hideNavigation={false}
            navigationRoute={route || null}
            selected="property"
            backUrl="/find"
            customTitle={translate('pageHeaders.editWatch')}
            maxWidth="page">
            <SearchWatcherAdd
              housetype={housetype}
              area={area}
              onSubmit={(params) => this.onUpdateWatcher(params, createWatcher)}
              clearTemporaryWatcher={clearTemporaryWatcher}
              auth={auth}
              deleteItem={(params) => this.onUpdateWatcher(params, deleteWatcher)}
              getHousesByWatchParams={getHousesByWatchParams}
              watcher={watcher}
              route={route}
            />
          </LoggedInContainer>
        )
      case 'favourites':
        return (
          <LoggedInContainer
            navigationRoute={route || null}
            selected="property"
            customTitle={null}
            maxWidth="page">
            <FavouritesFrontpage
              favourites={favourites}
              getMyFavourites={getMyFavourites}
              auth={auth}
              housetype={housetype}
            />
          </LoggedInContainer>
        )
    }
    return (
      <LoggedInContainer navigationRoute={route || null} selected="property" customTitle={null}>
        {' '}
        <PropertyFrontpage auth={auth} />{' '}
      </LoggedInContainer>
    )
  }

  render() {
    let obj = this.getElementByAction(this.props)
    return (
      <GoogleMapProvider>
        <div>{obj}</div>
      </GoogleMapProvider>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    error: state.auth.error,
    errorMessage: state.auth.errorMessage,
    auth: state.auth,
    comments: state.comments,
    housetype: state.housetype,
    property: state.property,
    watcher: state.watcher,
    area: state.area,
    favourites: state.favourites,
    messages: state.messages,
    license: state.license,

    propertyId: ownProps.params.propertyId,
    watcherId: ownProps.params.watcherId,
    imageId: ownProps.params.imageId,
    country: ownProps.params.country,
    city: ownProps.params.city,
    command: ownProps.params.command
  }
}

const mapDispatchToProps = (dispatch) => ({
  getHouseTypes: (country) => dispatch(HouseTypeActions.getHouseTypes(country)),
  getWatcherByParams: (params) => dispatch(SearchWatcherActions.getWatcherByParams(params)),
  getHousesByWatchParams: (params) => dispatch(SearchWatcherActions.getHousesByWatchParams(params)),
  getHiddenHousesByWatchId: (id) => dispatch(SearchWatcherActions.getHiddenHousesByWatchId(id)),
  createProperty: (formData) => dispatch(HouseActions.createProperty(formData)),
  getPropertyById: (id) => dispatch(HouseActions.getPropertyById(id)),
  getHouseStats: (id) => dispatch(HouseActions.getHouseStats(id)),
  unloadProperty: () => dispatch(HouseActions.unloadProperty()),
  deleteProperty: (id) => dispatch(HouseActions.deleteProperty(id)),
  getMyProperties: () => dispatch(HouseActions.getMyProperties()),
  getAreaPrice: (postal_code, houseType) =>
    dispatch(AreaActions.getAreaPrice(postal_code, houseType)),
  createWatcher: (formData) => dispatch(SearchWatcherActions.create(formData)),
  getWatcherById: (id) => dispatch(SearchWatcherActions.getById(id)),
  getMyWatchers: () => dispatch(SearchWatcherActions.getAll()),
  unloadWatcher: () => dispatch(SearchWatcherActions.unload()),
  getWatchResults: (id) => dispatch(SearchWatcherActions.getHousesByWatchId(id)),
  deleteWatcher: (id) => dispatch(SearchWatcherActions.deleteWatcher(id)),
  clearTemporaryWatcher: () => dispatch(SearchWatcherActions.clearTemporaryWatcher()),
  getMyFavourites: () => dispatch(FavoriteActions.getFavorites()),
  toggleFavorite: (id) => dispatch(FavoriteActions.toggleFavorite(id)),
  getWatchesByHouse: (id) => dispatch(HouseActions.getWatchesByHouse(id)),
  sendMessage: (data, successF, errorF) =>
    dispatch(MessageActions.sendMessage(data, successF, errorF)),
  loginRedirect: () => dispatch(routerActions.replace('/register')),
  watchRedirect: () => dispatch(routerActions.replace('/find')),
  resetMessagesSent: () => dispatch(MessageActions.clearMessageSent()),
  getFeaturedByComments: (query) => dispatch(getFeaturedByComments(query)),
  getFeaturedByArea: (query) => dispatch(getFeaturedByArea(query)),
  getFeaturedByMostLiked: (query) => dispatch(getFeaturedByMostLiked(query)),
  createImageComment: (query) => dispatch(createImageComment(query)),
  getImageCommentById: (query) => dispatch(getImageCommentById(query)),
  createImageLike: (query) => dispatch(createImageLike(query)),
  getUserLicenses: () => dispatch(getUserLicenses())
})

export default connect(mapStateToProps, mapDispatchToProps)(PropertyContainer)
