import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { pick } from 'lodash'
import styled from 'styled-components/macro'
import { useState, useCallback } from 'react'
import { renderWithThousandSeparators, numVal } from '../../utils/number'
import Color from '../../styles/color'
import Variables from '../../styles/variables'

const Range = Slider.createSliderWithTooltip(Slider.Range)

const RangeWrapper = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: ${Variables.bpSmall}) {
    flex-wrap: nowrap;
  }
`

const InputValues = styled.div`
  margin-bottom: 2rem;
  flex: 1 1 50%;
  width: 100%;
  display: flex;

  @media (min-width: ${Variables.bpSmall}) {
  }
`

const SubLabel = styled.div`
  flex: 1 1 20%;
  font-size: 0.875em;
  color: rgb(135, 135, 135);
`

const ValueLabel = styled.div`
  flex: 1 0 30%;
  font-size: 0.875em;
  color: rgb(135, 135, 135);
  text-align: right;

  will-change: contents;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: ${Variables.bpSmall}) {
    margin-right: 2rem;
  }
`

const RangeInputElement = styled(Range)`
  @media (min-width: ${Variables.bpSmall}) {
    flex: 1 0 50%;
  }

  .tooltip {
    display: none;
  }

  opacity: ${(p) => (p.disabled ? '0.5' : '1')};
`

const ValueInput = styled.input`
  padding: 1px 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin: 0 0.5rem;
  min-width: 0;
  display: inline-flex;
  text-align: center;
  white-space: nowrap;
  outline: none;
  height: 2rem;
  align-items: center;
  font-size: 1.25rem;
`

const trackColor = 'rgb(45,50,61)'
const railColor = 'rgb(243,243,243)'

const RenderValue = ({ value, isInput, onChange, toggleInputActive, min, max }) => {
  let onChangeLimited = useCallback(
    (event) => {
      if (!onChange) {
        return
      }

      let nextValue = numVal(event.target.value)

      if (typeof min === 'undefined' && typeof max === 'undefined') {
        return onChange(nextValue)
      }

      let maxValue = typeof max !== 'undefined' ? Math.min(nextValue, max) : nextValue
      let limitedValue = Math.max(maxValue, min)
      onChange(limitedValue)
    },
    [onChange, min, max]
  )

  if (isInput) {
    let size = String(value).length + 1

    return (
      <ValueInput
        size={size}
        value={value}
        onChange={onChangeLimited}
        type="text"
        onBlur={toggleInputActive}
      />
    )
  }

  return (
    <ValueInput as="span" onClick={toggleInputActive}>
      {renderWithThousandSeparators(value)}
    </ValueInput>
  )
}

const RangeInput = ({ input, subLabel, unit, ...props }) => {
  let [inputValueActive, setInputValueActive] = useState(false)
  let value = input.value || props.defaultValue

  // Dynamic max, can be changed by input
  let max = Math.max(value[1], props.max)
  let sliderDisabled = typeof props.max !== 'undefined' ? max > props.max : false

  return (
    <RangeWrapper>
      <InputValues>
        <SubLabel>{subLabel}</SubLabel>
        <ValueLabel>
          <RenderValue
            min={props.min}
            max={Math.min(value[1], max)}
            toggleInputActive={() => setInputValueActive((current) => !current)}
            value={value[0]}
            onChange={(val) => input.onChange([val, value[1]])}
            isInput={inputValueActive}
          />{' '}
          —{' '}
          <RenderValue
            min={Math.max(value[0], props.min)}
            toggleInputActive={() => setInputValueActive((current) => !current)}
            value={value[1]}
            onChange={(val) => input.onChange([value[0], val])}
            isInput={inputValueActive}
          />{' '}
          {unit}
        </ValueLabel>
      </InputValues>
      <RangeInputElement
        tipProps={{ prefixCls: 'tooltip' }}
        value={value}
        onChange={input.onChange}
        disabled={sliderDisabled}
        handleStyle={{
          width: '3rem',
          height: '3rem',
          marginTop: 'calc(-1.5rem + 1px)',
          border: '1px solid rgba(0,0,0,0.05)',
          boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
          backgroundColor: sliderDisabled ? '#ddd' : 'white'
        }}
        trackStyle={[{ backgroundColor: sliderDisabled ? Color.red : trackColor }]}
        railStyle={{ backgroundColor: railColor }}
        {...{ ...pick(props, 'min', 'allowCross', 'pushable', 'step'), max }}
      />
    </RangeWrapper>
  )
}

export default RangeInput
