import styled from 'styled-components/macro'
import Variables from '../../styles/variables'

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${Variables.bpSmall}) {
    flex-direction: row;
  }
`

export const LeftColumn = styled.div`
  order: 1;
  width: 100%;

  @media (min-width: ${Variables.bpSmall}) {
    order: 1;
    width: 50%;
    padding-right: 1.5rem;
  }
`

export const RightColumn = styled.div`
  order: 2;
  padding-bottom: 3.25rem;
  margin-bottom: 0.75rem;

  @media (min-width: ${Variables.bpSmall}) {
    order: 2;
    width: 50%;
    padding-left: 1.5rem;
  }
`

export const ResultsWrapper = styled.div`
  display: flex;
  margin-left: -1rem;
  flex-wrap: wrap;
  width: calc(100% + 1rem);

  @media (min-width: ${Variables.bpSmall}) {
    width: auto;
    margin-right: -1rem;
    flex-direction: row;
  }

  > div {
    margin-left: ${Variables.gutterHalf};
    width: calc(100% - ${Variables.gutterHalf});
    margin-bottom: ${Variables.gutter};

    @media (min-width: ${Variables.bpSmall}) {
      margin-right: ${Variables.gutterHalf};
      width: calc(50% - ${Variables.gutter});
    }
  }
`
