import React from 'react'
import { Link as ReactLink } from 'react-router'
import { mix } from 'polished'

import styled from 'styled-components/macro'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import fbLogo from '../../images/icon-facebook-logo.png'
import googleLogo from '../../images/icon-google-logo.png'

function isInternal(to) {
  // If it's a relative url such as '/path', 'path' and does not contain a protocol we can assume it is internal.
  return to.indexOf('://') === -1
}

const MenuLink = styled.span`
  display: block;
  text-decoration: none;
  font-weight: 700;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.2rem;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background-color: ${(props) => (props.selected ? Color.green : Color.white)};
    transition: background-color ${Variables.defaultSpeed} ${Variables.defaultEasing};
  }

  &:hover {
    &::after {
      background-color: ${Color.green};
    }
  }
`

const ButtonLink = styled.span`
  background-color: ${Color.green};
  padding: ${(props) => (props.size === 'small' ? '0.75rem 1.4rem' : '1rem 3.5rem')};
  display: inline-block;
  color: ${Color.white};
  border: 0.1rem solid ${Color.green};
  border-radius: 3rem;
  font-size: ${(props) => (props.size === 'small' ? '1.05rem' : '1.4rem')};
  transition: all ${Variables.defaultSpeed} ${Variables.defaultEasing};
  text-align: center;
  font-weight: 600;

  @media (min-width: ${Variables.bpSmall}) {
    padding: ${(props) => (props.size === 'small' ? '0.75rem 1.5rem' : '1.2rem 4rem')};
    font-size: ${(props) =>
      props.size === 'small' ? Variables.smallFontSize : Variables.bodyFontSize};
    min-width: ${(props) => (props.size === 'small' ? '0' : '18rem')};
  }

  &:hover {
    border-color: ${mix(0.7, Color.green, Color.white)};
    background-color: ${mix(0.7, Color.green, Color.white)};
  }
`

const ButtonOutlineLink = styled(ButtonLink)`
  background-color: transparent;
  color: ${Color.black};
  border-color: ${Color.borderDark};

  &:hover {
    border-color: ${mix(0.5, Color.borderDark, Color.white)};
    background-color: transparent;
  }
`

const ButtonNegativeLink = styled(ButtonLink)`
  background-color: ${Color.white};
  color: ${Color.black};
  border-color: ${Color.white};

  &:hover {
    border-color: ${mix(0.6, Color.white, Color.toggleGrey)};
    background-color: ${mix(0.6, Color.white, Color.toggleGrey)};
  }
`

const SomeButtonLink = styled(ButtonLink)`
  background-size: 1.8rem;
  padding-left: 4rem;
  padding-right: 4rem;
  text-align: left;

  img {
    width: 1.8rem;
    height: auto;
    vertical-align: text-bottom;
    margin-right: 1.5rem;
  }
`

const GoogleLink = styled(SomeButtonLink)`
  background-color: ${Color.white};
  color: ${Color.google};
  border: 0.1rem solid ${Color.google};

  &:hover {
    border-color: ${mix(0.6, Color.google, Color.white)};
    background-color: ${mix(0.6, Color.white, Color.toggleGrey)};
  }
`

const FacebookLink = styled(SomeButtonLink)`
  background-color: ${Color.blue};
  color: ${Color.white};
  border: 0.1rem solid ${Color.blue};

  &:hover {
    border-color: ${mix(0.8, Color.blue, Color.white)};
    background-color: ${mix(0.8, Color.blue, Color.white)};
  }
`

function Link(props) {
  const { to, children, type, style, selected, size, ...rest } = props
  const linkIsInternal = isInternal(to)

  let linkElement
  let extraStyle = { display: 'inline-block' }

  if (type === 'menu') {
    extraStyle = { textDecoration: 'none' }
    linkElement = (
      <MenuLink selected={selected} style={style}>
        {children}
      </MenuLink>
    )
  } else if (type === 'google') {
    extraStyle = { textDecoration: 'none' }
    linkElement = (
      <GoogleLink style={style} size={size}>
        <img src={googleLogo} />
        {children}
      </GoogleLink>
    )
  } else if (type === 'facebook') {
    extraStyle = { textDecoration: 'none' }
    linkElement = (
      <FacebookLink style={style} size={size}>
        <img src={fbLogo} />
        {children}
      </FacebookLink>
    )
  } else if (type === 'button') {
    extraStyle = { textDecoration: 'none' }
    linkElement = (
      <ButtonLink style={style} size={size}>
        {children}
      </ButtonLink>
    )
  } else if (type === 'outline') {
    extraStyle = { textDecoration: 'none' }
    linkElement = (
      <ButtonOutlineLink style={style} size={size}>
        {children}
      </ButtonOutlineLink>
    )
  } else if (type === 'negative') {
    extraStyle = { textDecoration: 'none' }
    linkElement = (
      <ButtonNegativeLink style={style} size={size}>
        {children}
      </ButtonNegativeLink>
    )
  } else if (type === 'no-deco') {
    extraStyle = { textDecoration: 'none' }
    linkElement = <span>{children}</span>
  } else {
    linkElement = <span>{children}</span>
  }

  if (linkIsInternal) {
    if (!rest.onClick) {
      return (
        <ReactLink
          style={extraStyle}
          to={to}
          onClick={() => {
            window.scrollTo(0, 0)
          }}
          {...rest}>
          {linkElement}
        </ReactLink>
      )
    } else {
      return (
        <ReactLink style={extraStyle} to={to} {...rest}>
          {linkElement}
        </ReactLink>
      )
    }
  } else {
    return (
      <a style={extraStyle} href={to} {...rest}>
        {linkElement}
      </a>
    )
  }
}

Link.defaultProps = {
  type: 'link'
}

export default Link
