import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Variables from '../styles/variables'

const Container = styled.div`
  position: relative;

  > div {
    width: ${(props) =>
      (props.maxWidth === 'col' && Variables.maxWidthCol) ||
      (props.maxWidth === 'none' && '100%') ||
      Variables.maxWidthPage};
    margin-left: auto;
    margin-right: auto;
    padding-left: ${Variables.gutter};
    padding-right: ${Variables.gutter};
    padding-bottom: ${Variables.paddingVerticalSectionBottom};
    min-height: calc(100vh - ${Variables.navigationHeight});
    max-width: 100%;

    @media (min-width: ${Variables.bpMobile}) {
      // Needs to be a non-zero value for some reason for the page heading margin to count *shrug*
      padding-top: 1px;
      padding-bottom: ${Variables.paddingVerticalSection};
    }
  }
`

export default class BasicPageContainer extends Component {
  static propTypes = {
    maxWidth: PropTypes.string,
    hasBackNav: PropTypes.bool,
    hasFooter: PropTypes.bool,
    style: PropTypes.object
  }

  static defaultProps = {
    maxWidth: 'page',
    hasBackNav: false,
    hasFooter: false
  }

  render() {
    const { children, style, ...other } = this.props

    return (
      <Container {...other}>
        <div style={style}>{children}</div>
      </Container>
    )
  }
}
